import {createRouter, createWebHistory} from 'vue-router';
import NotFound from '@/modules/frontend/views/NotFound.vue';

// 前台布局和路由配置
const frontEndRoutes = [
    {
        path: '',
        name: 'Home',
        component: () => import('@/modules/frontend/views/HomePage.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/modules/general/views/LoginPage.vue'),
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import('@/modules/general/views/SignupPage.vue'),
    },
    {
        path: 'listing',
        name: 'Listing',
        component: () => import('@/modules/frontend/views/ListingPage.vue'),
        props: (route) => ({
            kw: route.query.kw || '', // 将查询参数 kw 传递到组件
            cityID: route.query.cityID || '', // 将查询参数 cityID 传递到组件
            order: route.query.order || '' // 将查询参数 order 传递到组件
        }),
    },
    {
        path: 'details/:id',
        name: 'Details',
        component: () => import('@/modules/frontend/views/DetailsPage.vue'),
        props: true,
    },
    {
        path: 'developer',
        children: [
            {
                path: 'richydepark',
                name: 'RichyDePark',
                component: () => import('@/modules/frontend/views/developer/RichyDePark.vue'),
            }
        ]
    },
    {
        path:'upload',
        name: 'UploadPage',
        component: () => import('@/modules/general/views/UploadPage.vue')
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound,
    },
];

// 管理后台路由配置
const adminRoutes = [
    {
        path: '', // 空路径用于匹配 /admin
        redirect: {name: 'Dashboard'} // 重定向到 Dashboard
    },
    {
        path: 'dashboard',
        component: () => import('@/modules/admin/views/DashboardPage.vue'),
        name: 'Dashboard'
    },
    {
        path: 'profile',
        component: () => import('@/modules/admin/views/UserProfile.vue'),
        name: 'Profile'
    },
    {
        path: 'change-password',
        component: () => import('@/modules/admin/views/ChangePassword.vue'),
        name: 'ChangePassword'
    },
    {
        path: 'housing',
        component: () => import('@/modules/admin/housing/views/HousingOverview.vue'),
        children: [
            {
                path: 'properties',
                name: 'PropertyList',
                component: () => import('@/modules/admin/housing/views/PropertyList.vue'),
            },
            {
                path: 'edit-property/:id',
                name: 'EditProperty',
                component: () => import('@/modules/admin/housing/views/PropertyForm.vue'),
            },
            {
                path: 'add-property',
                name: 'AddProperty',
                component: () => import('@/modules/admin/housing/views/PropertyForm.vue'),
            },
        ],
    },

];

// 整体路由配置
const routes = [
    {
        path: '/',
        component: () => import('@/layouts/MainLayout.vue'),
        children: frontEndRoutes,
    },
    {
        path: '/admin',
        component: () => import('@/layouts/AdminLayout.vue'),
        children: adminRoutes,
        meta: {requiresAuth: true}, // 添加需要权限的标记
    },
];

// 创建路由器实例
const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

// 权限验证方法
function isAuthenticated() {
    // 从 localStorage 或 cookie 中获取用户信息
    const userData = localStorage.getItem('userData');
    return userData && JSON.parse(userData).token; // 根据实际验证条件调整
}

// 全局导航守卫
router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (isAuthenticated()) {
            next(); // 允许访问
        } else {
            next({name: 'Login'}); // 重定向到登录页
        }
    } else {
        next(); // 不需要权限，直接访问
    }
});
